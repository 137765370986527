import React, {Component} from "react"
import styled from 'styled-components'
import {graphql, StaticQuery} from 'gatsby'

import Layout from "components/Layout/Layout"
import Intro from "../components/HomePage/Intro"
import arrow from 'images/white-arrow.svg'

import {media} from "utils/Media"
import {GatsbyImage} from "gatsby-plugin-image";
import snowFlakesXs from "../images/snowflakes-mobile.svg"
import snowFlakesXl from "../images/snowflakes-desktop.svg"

const scrollToElement = require('scroll-to-element')


const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
  overflow: hidden;

  .enhance {
    text-align: center;

    @media ${media.lg} {
      text-align: left;
    }
  }

  .buttonWrapper {
    justify-content: center;
    margin-bottom: 0;

    @media ${media.lg} {
      justify-content: left;
    }
  }

  .fix-height {

    @media (min-width: 1200px) {
      min-height: 350px;
    }

    @media (min-width: 1400px) {
      min-height: 380px;
    }
    @media (min-width: 1500px) {
      min-height: 370px;
      padding: 0 20px;
    }
  }

  h2 {
    font-family: ${props => props.theme.font.family.special};
    color: ${props => props.theme.colors.navy};
    margin: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 40px;
    line-height: 48px;
  }

  hr {
    margin: 0;
  }

  #behind-the-scenes .container {
    max-width: 1400px !important;
  }
`


const VideoWrapper = styled.div`
  position: relative;
  overflow: hidden;
`

const VideoBg = styled.div`
  display: block;
  position: relative;
  z-index: 1;
  overflow: hidden;
  pointer-events: none;

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;

    @media ${media.lg} {
      display: block;
    }
  }

  &:after {
    content: "";
    background-color: rgba(0, 0, 0, .45);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -98;
  }
`

const Logo = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  width: 80%;

  @media ${media.lg} {
    max-width: 600px;
  }

  @media (min-width: 1400px) {
    max-width: 885px;
  }
`

const CopyOnVideo = styled.div`
  text-align: center;
  position: absolute;
  bottom: 1rem;
  width: 100%;
  z-index: 10;
  color: ${props => props.theme.colors.white};

`

const Arrow = styled.div`
  margin-top: 2rem;

  img {
    width: 30px;
    height: 16px;

    @media ${media.md} {
      width: 51px;
      height: 28px;
    }
  }
`

const SnowFlakes = styled.div`
  background: url("${snowFlakesXs}") repeat 50% 120%;
  background-size: 240%;

  @media ${media.sm} {
    background: url("${snowFlakesXl}") repeat 50% 70%;
    background-size: 290%;
  }

  @media ${media.md} {
    background: url("${snowFlakesXl}") repeat 50% 70%;
    background-size: 190%;
  }

  @media ${media.lg} {
    background: url("${snowFlakesXl}") repeat 50% 70%;
    background-size: 190%;
  }

  @media ${media.xl} {
    background: url("${snowFlakesXl}") no-repeat 50% 70%;
    background-size: 200%;
  }

  .narrow {
    max-width: 750px;
    margin: 0 auto;
  }
`
const Query = () => (
  <StaticQuery
    render={data => (
      <IndexPage data={data}/>
    )}
    query={graphql`
            query {
                FallbackXs: file(relativePath: { eq: "HomePage/new-768.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }

                FallbackMd: file(relativePath: { eq: "HomePage/new-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }

                FallbackLg: file(relativePath: { eq: "HomePage/new-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                PrideXs: file(relativePath: { eq: "HomePage/header-prideElsa-768.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }

                PrideMd: file(relativePath: { eq: "HomePage/header-prideElsa-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }

                PrideLg: file(relativePath: { eq: "HomePage/header-prideElsa-1920_v2.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }

                CloseApp: file(relativePath: { eq: "HomePage/close-app-phone.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 551, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }

                ForegroundBottom: file(relativePath: { eq: "foreground-bottom.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1920, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }

                Logo: file(relativePath: { eq: "HomePage/new-logo.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 880, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }

                videoPlaceholder1: file(relativePath: { eq: "coming-to-london-this-october.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1200, quality: 100)
                    }
                }

                videoPlaceholder2: file(relativePath: { eq: "HomePage/FROZEN-JUL23-dangerous-to-dream-video-poster-1084x610.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1084, quality: 100)
                    }
                }
            }
		`}
  />
)

class IndexPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      modalOpen: false,
      src: "https://secure.disney.com/embed/605f07c75829debd6b4f2a72?domain=www.disney.co.uk",
      showImages: false,
      ticketModalOpen: false,
      distance: 1000
    }
  }
  
  toggleModal = ( title, src) => {
    // event.preventDefault();
    
    this.setState({
      src: src,
      title: title,
      modalOpen: !this.state.modalOpen
    });
  }
  
  onClick = () => {
    scrollToElement('#bottomOfIframe', {
      duration: 300
    });
  }
  
  
  render() {
    return (
      <Layout pageName="homepage" title="Homepage" slug="">
        <PageWrapper>
          <VideoWrapper>
            <VideoBg>
              <GatsbyImage
                image={this.props.data.FallbackXs.childImageSharp.gatsbyImageData}
                alt=""
                title=""
                className="img-fluid d-block d-md-none"
              />
              <GatsbyImage
                image={this.props.data.FallbackMd.childImageSharp.gatsbyImageData}
                alt=""
                title=""
                className="img-fluid d-none d-md-block d-lg-none"
              />
              <GatsbyImage
                image={this.props.data.FallbackLg.childImageSharp.gatsbyImageData}
                alt=""
                title=""
                className="img-fluid d-none d-lg-block"
              />
            
            </VideoBg>

            <CopyOnVideo>
              <Arrow>
                <img className="bounceUpAndDown" src={arrow} alt="" onClick={this.onClick}/>
              </Arrow>
            </CopyOnVideo>
          </VideoWrapper>
          <SnowFlakes>
            <Intro/>
          </SnowFlakes>
        </PageWrapper>
      </Layout>
    )
  }
}

export default Query
