import React, {Component} from "react"
import {StaticQuery, graphql, Link} from "gatsby"
import {Container} from "reactstrap"
import styled from "styled-components"
import {media} from "utils/Media"
import PreviewModal from '../shared/PreviewModal'

const IntroBg = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 0 2rem;

     @media ${media.sm} {
        padding: 0 2rem 3rem;
     }

`

const IntroContent = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 4;
    
    /* .container {
        @media ${media.xl} {
            max-width: 1400px !important;
        }
    } */

    .narrow {
        max-width: 681px;
    }


    h2 {
        font-family: ${props => props.theme.font.family.special};
        color: ${props => props.theme.colors.navy};
        margin: 0;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
`

const Content = styled.div`

    max-width: 1000px !important;
    margin: 0 auto;
    padding: 2rem 0;

    h3 {
        font-family: "Turquoise W05 Inline";
        color: rgb(0, 70, 132);
        font-size: 2rem;
    }
    
    @media ${media.xl} {
        //display: flex;
        //justify-content: center;
        //align-items: center;
        //
        //.flex-helper {
        //    width: 50%;
        //}
        
    }
`

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                allChristmasScheduleJson {
                    edges {
                        node {
                            date
                            time
                        }
                    }
                }
                BgImageXs: file(relativePath: { eq: "bg-performances-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }

                BgImageSm: file(relativePath: { eq: "bg-performances-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }

                BgImageLg: file(relativePath: { eq: "bg-performances-1200.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1200, quality: 100)
                    }
                }

                BgImageXl: file(relativePath: { eq: "bg-performances-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }


               teaser: file(relativePath: { eq: "CastAndCreativePage/Frozen_JUL20_Full-Cast-Grid_1159x1920_AW.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1200, quality: 100)
                    }
                }

            }
		`}
        render={data => (
            <Intro id={props.id} data={data}/>
        )}
    />
)

class Intro extends Component {
    constructor(props) {
        super(props);

        this.state = {
            previewModalOpen: false,
        }
    }

    togglePreviewModal = (e, src) => {
        e.preventDefault();

        this.setState({
            src: src,
            previewModalOpen: !this.state.previewModalOpen
        });
    }

    render() {
        return (
            <IntroBg>
                <IntroContent>
                    <Container fluid={true}>
                        <Container>
                            <Content>
                                <h3 className="text-center narrow mb-2 mx-auto">Disney's Frozen the Musical concluded its run at Theatre Royal Drury Lane in London on 8 September 2024 after three magical years.</h3>
                                <p className="text-center text-black narrow mb-2 mx-auto">Thank you to all the cast, crew, orchestra and especially our beloved audiences who have made this journey unforgettable. The magic ones are you.</p>

                                <p className="text-center text-black narrow mb-2 mx-auto">Watch this short behind-the-scenes video to see some of the talent and dedication that made Frozen so magical.</p>
                            </Content>

                            <div className="embed-responsive embed-responsive-16by9">
            <iframe 
              src="https://secure.disney.com/embed/62160a6ea1d06decb4951fce?domain=www.disney.co.uk" width="1024" height="576" frameborder="0" allowfullscreen className="embed-responsive-item"></iframe>
          </div>
                        </Container>
                    </Container>
                </IntroContent>

                <PreviewModal toggleModal={this.togglePreviewModal} modalOpen={this.state.previewModalOpen}/>
            </IntroBg>
        )
    }
}

export default Query
